<template>
  <section class="regions">
    <div class="w-100 mt-3">
<!--      <custom-filter @handleSearch="fetchList" @handleAdd="handleAdd"></custom-filter>-->
      <custom-filter @handleSearch="handleSearch" :search.sync="filters.search" @handleAdd="handleAdd" :createButton="false">
      </custom-filter>
      <b-card>
        <b-table :items="datas" :fields="fields">
          <template #cell(action)="data">
            <div class="d-flex">
              <b-button variant="primary" size="sm" class="ml-1" @click="handleEdit(data.item.id)" >
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end mt-3">
          <b-pagination
              v-model="pagination.page"
              hide-goto-end-buttons
              :total-rows="total"
              :per-page="pagination.pageSize"
              @input="fetchList()"
          />
        </div>
      </b-card>
    </div>
    <b-modal id="modal-lg" size="xl" scrollable hide-footer :title="modalTitle" v-model="modalVisible" >
      <Form :id="id" @saved="fetchList" />
    </b-modal>
    <b-modal size="lg" hide-footer :title="$t('message.CauseOfReject')" v-model="RejectModal">
      <div class="w-100">
        <b-form-group>
          <b-form-textarea
              id="textarea"
              v-model="reject.cause"
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="w-100 mt-2 d-flex justify-content-end">
        <b-button variant="danger" class="mr-1">{{$t('message.Save')}}</b-button>
        <b-button variant="dark" @click="RejectModal=false">{{$t('message.Cancel')}}</b-button>
      </div>
    </b-modal>
    <b-modal size="lg" hide-footer :title="$t('message.Accept')" v-model="AcceptModal">
      <div class="w-100">
        <b-form-group>
          <b-form-textarea
              id="textarea"
              v-model="accept.cause"
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="w-100 mt-2 d-flex justify-content-end">
        <b-button variant="danger" class="mr-1">{{$t('message.Save')}}</b-button>
        <b-button variant="dark" @click="AcceptModal=false">{{$t('message.Cancel')}}</b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {getJuryApplications, deleteJuryApplication} from '@/api/jury';
import { getStatuses, rejectApplication } from '@/api/application'
import { listTemplate } from '@/utils/mixins/listTemplate'
import CustomFilter from '@/views/components/CustomFilter'
import Form from './Form'

const actions = {
  get: getJuryApplications,
  delete: deleteJuryApplication,
}

export default {
  name: 'index',
  components: {
    CustomFilter,
    Form,
  },
  mixins:[
    listTemplate,
  ],
  created() {
    getStatuses().then(res => {
      this.statuses = res.data.data
      this.statuses.unshift({ id: null, name: this.$t('message.Status') })
    })
  },
  data() {
    return {
      actions,
      fields: [
        { key: 'id', label: this.$t('ID') },
        { key: 'nomination.title', label: this.$t('message.Nomination') },
        { key: 'user_marks', label: this.$t('message.Mark') },
        { key: 'status.name', label: this.$t('message.Status') },
        { key: 'action', label: this.$t('message.Action') },
      ],
      filters: {
        status_id: null,
        marked: true,
        stage_id: this.$route.params.stageId,
        search: null,
      },
      RejectModal: false,
      AcceptModal: false,
      reject: {
        cause:null,
        application_id: null,
      },
      accept: {
        cause:null,
        application_id: null,
      },
    }
  },
  methods: {
    getSearch() {
      this.pagination.page = 1;
      this.fetchList()
    },
    getClear() {
      this.pagination.page = 1;
      this.filters.search = null;
      this.fetchList()
    },
    handleSearch(search) {
      this.filters.search = search
      this.fetchList()
    },

  }
}
</script>

<style scoped>

</style>
